<template>
  <div class="parceiro">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo">Parceiro</p>
          <p>Estamos em busca de parcerias que possam sempre trazer novos vídeos e um pouco mais sobre o nosso servidor para os jogadores. Ao fazer parte da nossa equipe de parceiros, você recebe benefícios exclusivos em todas as nossas ferramentas e plataformas! Mas lembre-se, você será o responsável por representar o Palaze para toda a comunidade que lhe segue, por isso somos seletos em cada parceiro que aceitamos.</p>
          <form @submit.prevent="onSubmit()">
            <div class="form-group">
              <label for="plataforma">Qual plataforma você utiliza?</label>
              <select class="form-select" aria-label="Default select example" id="plataforma" v-model="plataforma" required>
                <option selected>Escolha a plataforma</option>
                <option value="t">Twitch</option>
                <option value="y">Youtube</option>
              </select>
              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
            </div>
            <div class="form-group mt-2">
              <label for="nome">Seu nome completo</label>
              <input type="text" class="form-control" id="nome" v-model="nome" placeholder="João da Silva" required>
            </div>
            <div class="form-group mt-2">
              <label for="nascimento">Sua data de nascimento</label>
              <input type="date" class="form-control" id="nascimento" v-model="nascimento" placeholder="DD/MM/AAAA" required>
            </div>
            <div class="row">
              <div class="form-group mt-2 col-6">
                <label for="discord">Nome de usuário do Discord</label>
                <input type="text" class="form-control" id="discord" v-model="discord" placeholder="zero" required>
              </div>
              <div class="form-group mt-2  col-6">
                <label for="tag">Tag do Discord</label>
                <input type="text" class="form-control" id="tag" v-model="tag" placeholder="#3333" required>
              </div>
            </div>
            <div class="form-group mt-2">
              <label for="canal">Link do seu canal da Twitch ou do Youtube:</label>
              <input type="text" class="form-control" id="canal" v-model="canal" placeholder="Link do seu canal" required>
            </div>
            <div class="form-check mt-3">
              <input type="checkbox" class="form-check-input" id="aceito" v-model="aceito" required>
              <label class="form-check-label" for="aceito">Você confirma que todas as informações ditas por você são verdadeiras e caso encontremos alguma irregularidade nas informações passadas, todas as suas contas serão permanentemente bloqueadas de nosso programa de parceiros.</label>
            </div>
            <button type="submit" class="btn btn-padrao btn-rosa mt-4">Aplicar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.parceiro {
  background-color: #eee;
  .titulo {
    font-size: 16px;
    font-weight: 700;
  }
}

@media (min-width: 992px) {
  .parceiro {
    flex-grow: 1;
    padding: 0 35px;
  }
}
</style>

<script>
export default {
  name: 'Parceiro',
  data() {
    return {}
  },
  components: {
  },
  methods: {
    onSubmit() {
      console.log()
    }
  }
}
</script>