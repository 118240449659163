<template>
  <div class="aplicacoes">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo">Aplicações</p>
        </div>
        <div class="col-12 mt-3">
          <div class="row">
            <div class="col-6 text-center">
              <p>Parceiro</p>
              <router-link to="/admin/parceiro" class="btn btn-padrao btn-rosa">Aplicar</router-link>
            </div>
            <div class="col-6 text-center">
              <p>Moderação</p>
              <router-link to="/admin/moderacao" class="btn btn-padrao btn-rosa">Aplicar</router-link>
            </div>
          </div>
        </div>
        <div class="col-12 mt-5">
          <p>Visualizar aplicações</p>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Área</th>
                <th scope="col">Status</th>
                <th scope="col">Data de envio</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Parceiro</th>
                <td>Aprovado</td>
                <td>19/10/2021</td>
              </tr>
              <tr>
                <th scope="row">Moderação</th>
                <td>Em Análise</td>
                <td>19/10/2021</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aplicacoes {
  background-color: #eee;
  .titulo {
    font-size: 16px;
    font-weight: 700;
  }
}

@media (min-width: 992px) {
  .aplicacoes {
    flex-grow: 1;
    padding: 0 35px;
  }
}
</style>

<script>
export default {
  name: 'Aplicacoes ',
  data() {
    return {}
  },
  components: {
  },
  methods: {
    onSubmit() {
      console.log()
    }
  }
}
</script>