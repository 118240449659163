<template>
  <div class="primeiroAcesso">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo rosa-padrao-cor">PRIMEIRO ACESSO</p>
          <p class="mb-4">Como esse é o seu primeiro acesso, precisamos que você confirme o seu email.</p>
          <form @submit.prevent="onSubmit()">
            <div class="form-group">
              <label for="user">Email</label>
              <input type="email" class="form-control" id="email" v-model="email" placeholder="Digite o seu Email">
              <!-- <small id="emailHelp" class="form-text text-muted">Como esse é o seu primeiro acesso, precisamos que você confirme o seu email.</small> -->
            </div>
            <div class="form-group mt-2">
              <label for="user">Confirme o email</label>
              <input type="email" class="form-control" id="emailConfirm" v-model="emailConfirm" placeholder="Confirme o seu Email">
              <!-- <small id="emailHelp" class="form-text text-muted">Como esse é o seu primeiro acesso, precisamos que você confirme o seu email.</small> -->
            </div>
            <button type="submit" class="btn btn-padrao btn-rosa mt-4">Cadastrar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.primeiroAcesso {
  .titulo {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
}
</style>

<script>
export default {
  name: 'PrimeiroAcesso ',
  data() {
    return {
      email: '',
      emailConfirm: ''
    }
  },
  components: {
  },
  methods: {
    onSubmit() {
      console.log(this.email, this.emailConfirm)
    }
  }
}
</script>