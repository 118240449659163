<template>
  <div class="admin">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="bem-vindo">BEM VINDO, USUÁRIO</p>
          <p class="data fonte-secundaria"><span class="rosa-padrao-cor">Sexta</span>, 06 Agosto 2021</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.admin {
  background-color: #eee;
  .bem-vindo {
    font-size: 16px;
    font-weight: 700;
  }
  .data {
    font-size: 12px;
    font-weight: 600;
  }
}

@media (min-width: 992px) {
  .admin {
    flex-grow: 1;
    padding-left: 35px;
  }
}
</style>

<script>
export default {
  name: 'Admin ',
  data() {
    return {}
  },
  components: {
  }
}
</script>