<template>
  <div class="contaVerificada">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo rosa-padrao-cor">CONTA VERIFICADA</p>
          <p class="mb-4">A sua conta foi verificada com sucesso!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contaVerificada {
  .titulo {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
}
</style>

<script>
export default {
  name: 'ContaVerificada ',
  data() {
    return {
    }
  },
  components: {
  },
  methods: {
  }
}
</script>