<template>
  <div class="informacoes">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo">Informações</p>
          <form @submit.prevent="onSubmit()">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="text" class="form-control" id="email" v-model="email" placeholder="Email">
              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
            </div>
            <div class="form-group mt-2">
              <label for="password">Senha</label>
              <input type="password" class="form-control" id="password" v-model="password" placeholder="Senha">
            </div>
            <button type="submit" class="btn btn-padrao btn-rosa mt-4">Salvar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.informacoes {
  background-color: #eee;
  .titulo {
    font-size: 16px;
    font-weight: 700;
  }
}

@media (min-width: 992px) {
  .informacoes {
    flex-grow: 1;
    padding: 0 35px;
  }
}
</style>

<script>
export default {
  name: 'Informacoes ',
  data() {
    return {}
  },
  components: {
  },
  methods: {
    onSubmit() {
      console.log()
    }
  }
}
</script>