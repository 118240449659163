<template>
  <div class="recuperar">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo rosa-padrao-cor">RECUPERAR SENHA</p>
          <form @submit.prevent="onSubmit()">
            <div class="form-group">
              <label for="password">Senha</label>
              <input type="password" class="form-control" id="password" v-model="password" placeholder="Senha">
              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
            </div>
            <div class="form-group mt-2">
              <label for="confirmPassword">Confirme a senha</label>
              <input type="password" class="form-control" id="confirmPassword" v-model="confirmPassword" placeholder="Confirme a Senha">
            </div>
            <button type="submit" class="btn btn-padrao btn-rosa mt-4">Entrar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.recuperar {
  .titulo {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
}
</style>

<script>
export default {
  name: 'RecuperarSenha ',
  data() {
    return {
      password: '',
      confirmPassword: '',
    }
  },
  components: {
  },
  methods: {
    onSubmit() {
      console.log(this.password, this.confirmPassword)
    }
  }
}
</script>