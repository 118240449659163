<template>
  <div class="aplicacao">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo">Finalização</p>
          <p>Formulário enviado! Lembre-se, se sua aplicação for aceita, entraremos em contato com você pelo Discord dentro de 72 horas após a criação do seu formulário, fique atento.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.aplicacao {
  background-color: #eee;
  .titulo {
    font-size: 16px;
    font-weight: 700;
  }
}

@media (min-width: 992px) {
  .aplicacao {
    flex-grow: 1;
    padding: 0 35px;
  }
}
</style>

<script>
export default {
  name: 'AplicacaoFinalizada',
  data() {
    return {}
  },
  components: {
  },
  methods: {
    onSubmit() {
      console.log()
    }
  }
}
</script>