<template>
  <div class="moderacao">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo">Moderação</p>
          <p>A aplicação para equipe de moderação é realizada de forma simples: inicialmente, você deve preencher e enviar seu formulário, e, com isso, ele estará sujeito a ser analisado, caso esteja dentro de nossos padrões com o uso correto da ortografia, um histórico apropriado, sendo honesto e verdadeiro em todas as suas respostas. Nosso principal objetivo por meio dos formulários é selecionar os jogadores mais capacitados, por isso, é extremamente importante que você se apresente da forma correta e torne interessante a sua presença na equipe.</p>
          <form @submit.prevent="onSubmit()">
            <div class="form-group">
              <label for="nome">Seu nome completo</label>
              <input type="text" class="form-control" id="nome" v-model="nome" placeholder="João da Silva" required>
            </div>
            <div class="form-group mt-2">
              <label for="nascimento">Sua data de nascimento</label>
              <input type="date" class="form-control" id="nascimento" v-model="nascimento" placeholder="DD/MM/AAAA" required>
            </div>
            <div class="row">
              <div class="form-group mt-2 col-6">
                <label for="discord">Nome de usuário do Discord</label>
                <input type="text" class="form-control" id="discord" v-model="discord" placeholder="zero" required>
              </div>
              <div class="form-group mt-2  col-6">
                <label for="tag">Tag do Discord</label>
                <input type="text" class="form-control" id="tag" v-model="tag" placeholder="#3333" required>
              </div>
            </div>
            <div class="form-group mt-2">
              <label for="periodo">Em que período você está disponível?</label>
              <select class="form-select" aria-label="Default select example" id="periodo" v-model="periodo" required>
                <option selected>Escolha o período</option>
                <option value="m">Manhã</option>
                <option value="t">Tarde</option>
                <option value="n">Noite</option>
              </select>
            </div>
            <div class="form-group mt-2">
              <label for="motivacao">Conte um pouco sobre você e sua motivação para integrar à equipe.</label>
              <textarea rows="5" class="form-control" id="motivacao" v-model="motivacao" required></textarea>
            </div>
            <div class="form-group mt-2">
              <label for="servidores">Diga-nos o(s) servidor(es) que você possui experiência e o motivo de sua saída em cada um. Não poupe detalhes.</label>
              <textarea rows="5" class="form-control" id="servidores" v-model="servidores" required></textarea>
            </div>
            <div class="form-check mt-3">
              <input type="checkbox" class="form-check-input" id="aceito" v-model="aceito" required>
              <label class="form-check-label" for="aceito">Você confirma que todas as informações ditas por você são verdadeiras e, caso encontremos alguma irregularidade nas informações passadas, todas suas contas estarão permanentemente bloqueadas de nosso processo seletivo para aplicação à equipe de moderação.  Sua entrada na equipe de moderação é totalmente voluntária, sendo assim está ciente que seu serviço prestado a nós é de pura e espontânea vontade.</label>
            </div>
            <button type="submit" class="btn btn-padrao btn-rosa mt-4">Aplicar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.moderacao {
  background-color: #eee;
  .titulo {
    font-size: 16px;
    font-weight: 700;
  }
}

@media (min-width: 992px) {
  .moderacao {
    flex-grow: 1;
    padding: 0 35px;
  }
}
</style>

<script>
export default {
  name: 'Moderacao',
  data() {
    return {}
  },
  components: {
  },
  methods: {
    onSubmit() {
      console.log()
    }
  }
}
</script>